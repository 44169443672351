import FontFaceObserver from "fontfaceobserver"
import { Power3, TimelineMax } from "gsap"
import React from "react"
import inView from "../../helpers/inView"
import styles from "./text.module.scss"

interface Props {
  text: string
  className?: string
  animateLines?: boolean
}
class Text extends React.Component<Props> {
  text: any
  constructor(props: Props) {
    super(props)
    this.text = React.createRef()
  }
  componentDidMount() {
    if (this.text.current) {
      const font = new FontFaceObserver("GT Walsheim")
      font.load().then(() => {
        if (this.props.animateLines) {
          const Splitting = require("splitting")
          const results = Splitting({ target: this.text.current, by: "lines" })
          this.text.current.innerHTML = ""
          results[0].lines.forEach((line) => {
            const newline = document.createElement("span")
            newline.classList.add(styles.line)
            line.forEach((word) => {
              newline.innerHTML += " " + (word.dataset.word || word.innerHTML)
            })
            this.text.current.appendChild(newline)
          })
          this.text.current.classList.remove(styles.init)
          inView(results[0].el, (entry) => {
            const domLines = entry.target.getElementsByClassName(styles.line)
            const tl = new TimelineMax()
            tl.staggerTo(
              domLines,
              1.2,
              { y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.4 },
              0.2
            )
          })
        } else {
          inView(this.text.current, (entry) => {
            const tl = new TimelineMax()
            const wrapper = document.createElement("div")
            wrapper.classList.add("block")
            entry.target.parentNode.insertBefore(wrapper, entry.target)
            wrapper.appendChild(entry.target)

            tl.staggerTo(
              [wrapper],
              1.2,
              { y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.4 },
              0.2
            )
          })
        }
      })
    }
  }
  render() {
    return (
      <div
        ref={this.text}
        className={`${styles.init} ${this.props.className}`}
        dangerouslySetInnerHTML={{ __html: this.props.text }}
      />
    )
  }
}
export default Text
