import React from "react"
import Text from "../lazy-loaders/text"
import styles from "./brand_voice.module.scss"

interface TimelineItem {
  text: string
  title?: string
}

interface Props {
  title: string
  introText: string
  timelineItems?: TimelineItem[]
  className?: string
}
export default ({ title, introText, timelineItems, className }: Props) => (
  <div className={`${styles.brandVoice} ${className ? ` ${className}` : ""}`}>
    <h2 className={styles.title}>
      <Text text={title} />
    </h2>
    <div className={styles.introText}>
      <Text animateLines={true} text={introText} />
    </div>

    <div
      className={`${styles.timelineWrap} ${
        timelineItems && !!timelineItems.length
          ? styles.timelineWrapWithItems
          : ""
      }`}
    >
      {timelineItems &&
        !!timelineItems.length &&
        timelineItems.map((timelineItem, i) => (
          <div className={styles.timelineText} key={i}>
            {timelineItem.title && (
              <h3>
                <Text text={timelineItem.title} />
              </h3>
            )}
            <Text animateLines={false} text={timelineItem.text} />
          </div>
        ))}
    </div>
  </div>
)
